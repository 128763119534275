import React, { useState } from "react";
import styled from "styled-components";
import NukaCarousel from "nuka-carousel";
import { useCallback } from "react";
import * as Theme from "../../Theme";

const ratio = 880 / 1320;

function Carousel(props) {
  const { files, slideIndex, setSlideIndex } = props;

  const renderLeftIcon = useCallback(
    ({ previousSlide }) => (
      <IconContainer onClick={previousSlide} style={{ left: 10 }}>
        {/* <img src="/images/arrow-reverse.png" alt="left-arrow" width="60" /> */}
        <div className="arrow left" />

        {/* <Icon.KeyboardArrowLeft color="white" style={{ width: 35, height: 35 }} /> */}
      </IconContainer>
    ),
    []
  );

  const renderRightIcon = useCallback(
    ({ nextSlide }) => (
      <IconContainer onClick={nextSlide} style={{ right: 10 }}>
        {/* <img src="/images/arrow.png" alt="right-arrow" width="60" /> */}
        <div className="arrow right" />
        {/* <Icon.KeyboardArrowRight
        color="white"
        style={{ width: 35, height: 35 }}
      /> */}
      </IconContainer>
    ),
    []
  );

  return (
    <NukaCarousel
      width={"100%"}
      height={"100%"}
      renderBottomCenterControls={() => false}
      renderBottomLeftControls={renderLeftIcon}
      renderBottomRightControls={renderRightIcon}
      renderCenterLeftControls={false}
      renderCenterRightControls={false}
      slideIndex={slideIndex}
      afterSlide={slideIndex => setSlideIndex(slideIndex)}
      // autoplay={true}
      // pauseOnHover={true}
    >
      {files.map((d, i) => (
        <ImageContainer key={"carousel-image" + i}>
          <img
            width="auto"
            height="100%"
            src={d.expected_url}
            style={{ position: "absolute", top: 0, left: 0, right: 0 }}
            alt={d.file_name}
          />
        </ImageContainer>
      ))}
    </NukaCarousel>
  );
}

const IconContainer = styled.button`
  /* display: flex;
  justify-content: center;
  align-items: center; */

  cursor: pointer;
  background-color: transparent;
  border: 0px;
  width: 60px;
  height: 25.5px;
  margin: 20px;

  & > .arrow {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  & > .right {
    background-image: url("/images/arrow.png");
  }
  & > .left {
    background-image: url("/images/arrow-reverse.png");
  }

  :hover {
    & > .right {
      background-image: url("/images/arrow-hover.png");
    }
    & > .left {
      background-image: url("/images/arrow-reverse-hover.png");
    }
  }

  :focus {
    outline: none;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  overflow: hidden;
  padding-top: ${ratio * 100}%;
`;

function Thumbnails({ files, setSlideIndex }) {
  return (
    <div style={{ display: "flex", maxWidth: "100%", overflowX: "scroll" }}>
      {files.map((file, idx) => (
        <Thumbnail
          key={idx}
          src={file.expected_url}
          alt={file.file_name}
          onClick={() => setSlideIndex(idx)}
        />
      ))}
    </div>
  );
}

const Thumbnail = styled.img`
  width: 160px;
  height: ${ratio * 160}px;
  object-fit: cover;
  margin-right: 10px;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`;

export default function ImageSection({ record }) {
  const [slideIndex, setSlideIndex] = useState(0);

  return (
    <div style={{ width: "100%", marginBottom: 60 }}>
      <Carousel
        files={record.files}
        slideIndex={slideIndex}
        setSlideIndex={setSlideIndex}
      />
      <div style={{ height: 10 }} />
      <Thumbnails files={record.files} setSlideIndex={setSlideIndex} />
    </div>
  );
}
