import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import ActionCreator from "../../ActionCreator";
import Constants from "../../constants";
import { Row, Col, Pagination } from "antd";
import styled from "styled-components";
import * as Theme from "../../Theme";
import useDimension from "../../hooks/use-dimension";
import { Divide } from "styled-icons/typicons";
import { SendPlane } from "@styled-icons/remix-fill/SendPlane";
import StaticImage from "../../Components/StaticImage";
import Button from "../../Components/Button";
import { navigate, Link } from "gatsby";
import { Context } from "../../AppContext";
import { errorHandler, ErrWorks } from "../../errors";
import SwapLeftOutlined from "@ant-design/icons/SwapLeftOutlined";
import SwapRightOutlined from "@ant-design/icons/SwapRightOutlined";
import Breadcrumb from "../../Components/Breadcrumb";
import Preview from "rev.sdk.js/Components/RichTextPreview";
import ShareLinks from "./ShareLinks";
import PreviousNextButton from "./PreviousNextButton";
import ImageSection from "./WorkCarousel";
const qs = require("query-string");

// import { SendOutlined } from "@ant-design/icons";

export default function WorksPage(props) {
  /*
   * id come from
   *     /product/?id=xxx
   *  or
   *     /product/xxx
   */
  const params = qs.parse(props.location.search) || {};
  let id = "";
  if (params.id) {
    id = params.id;
  } else {
    id = (url => {
      let _u = url.replace(/\/+$/, "");
      return _u.substring(_u.lastIndexOf("/") + 1);
    })(props.location.pathname);
    params.id = id;
  }

  const { dimension, rwd } = useDimension();
  const pad = dimension.innerWidth <= Theme.breakpoints.lg;
  const mobile = dimension.innerWidth <= Theme.breakpoints.xs;
  const app = useContext(Context);
  const [record, setRecord] = useState(null);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    (async () => {
      app.actions.setLoading(true);
      setFetching(true);
      try {
        let resp = await app.actions.getWork(id);
        setRecord(resp);
      } catch (err) {
        errorHandler(err);
      }
      app.actions.setLoading(false);
      setFetching(false);
    })();
  }, [id]);

  if (fetching) {
    return <div />;
  }

  if (!record) {
    return (
      <Wrapper isPad={pad} rwd={rwd}>
        <div className="constraint">
          <h2>找不到 Work</h2>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper isPad={pad} rwd={rwd}>
      <div className="constraint">
        <h2>{record.title}</h2>
        <Breadcrumb
          routes={[
            { label: "WORKS", link: "/works/" },
            {
              label: record.label?.slice(0, 1) || "ALL",
              link: `/works/?label=${record.label?.slice(0, 1) || "ALL"}`,
            },
            { label: record.title },
          ]}
          seperator=">"
          style={{ marginBottom: 30 }}
        />

        <ImageSection record={record} />

        <div className="bottom-section">
          <div className="left">
            <Preview content={record.variant} />
          </div>
          <div className="right">
            <Preview content={record.content} />
          </div>
        </div>

        <div
          style={{
            height: 1,
            width: "100%",
            backgroundColor: "#707070",
            margin: "20px 0",
          }}
        />

        <div
          style={{ display: "flex", alignItems: "center", marginBottom: 50 }}
        >
          <PreviousNextButton id={id} />
          <div style={{ flex: 1 }} />
          <ShareLinks instance={record} id={id} style={{}} />
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #fff;
  flex: 1;
  padding-top: 50px;

  & > .constraint {
    max-width: 1000px;
    margin: 0 auto;
  }

  & h2 {
    font-weight: 600;
    margin-bottom: 15px;
  }

  & .bottom-section {
    display: flex;
    margin-bottom: 50px;

    & > .left {
      flex: 1;
      margin-right: 30px;
    }
    & > .right {
      flex: 2;
    }
    & > .left,
    .right {
      & p,
      blockquote,
      li,
      a {
        color: #777777;
        font-size: 14px;
        line-height: 1.8;
      }
    }
  }

  @media screen and (max-width: ${Theme.centerContentMaxWidth}) {
    padding: 40px;
  }

  @media screen and (max-width: ${Theme.breakpoints.lg}px) {
    & .bottom-section {
      flex-direction: column;

      & > .left {
        order: 2;
      }
      & > .right {
        order: 1;
        margin-bottom: 30px;
      }
    }
  }

  @media screen and (max-width: ${Theme.breakpoints.xs}px) {
    padding: 20px;
  }
`;
