import React, { useContext, useEffect, useMemo, useState } from "react";
import { ChevronWithCircleRight } from "@styled-icons/entypo/ChevronWithCircleRight";
import { ChevronWithCircleLeft } from "@styled-icons/entypo/ChevronWithCircleLeft";
import { Link } from "gatsby";
import { Context } from "../../AppContext";

export default function PreviousNextButton({ id }) {
  const [records, setRecords] = useState([]);
  const app = useContext(Context);

  useEffect(() => {
    (async () => {
      app.actions.setLoading(true);
      try {
        let resp = await app.actions.getWorks({
          query: {},
          projection: {
            variant: 0,
            content: 0,
            label: 0,
            created: 0,
            updated: 0,
            title: 0,
            files: 0,
          },
        });
        setRecords(resp.map(w => w.id));
      } catch (err) {
        errorHandler(err);
      }
      app.actions.setLoading(false);
    })();
  }, []);

  const { previous, next } = useMemo(() => {
    let p, n;
    if (id && records.length > 0) {
      const index = records.indexOf(id);
      p = index > 0 ? records[index - 1] : null;
      n = index < records.length - 1 ? records[index + 1] : null;
    }
    return { previous: p, next: n };
  }, [id, records]);

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {previous && (
        <Link
          style={{ display: "flex", alignItems: "center" }}
          to={`/work/${previous}`}
        >
          <ChevronWithCircleLeft color="#909090" size="22" />
          <div style={{ margin: "0 5px", color: "#707070", fontSize: 16 }}>
            PREV
          </div>
        </Link>
      )}
      {next && (
        <Link
          style={{ display: "flex", alignItems: "center" }}
          to={`/work/${next}`}
        >
          <div style={{ margin: "0 5px", color: "#707070", fontSize: 16 }}>
            NEXT
          </div>
          <ChevronWithCircleRight color="#909090" size="22" />
        </Link>
      )}
    </div>
  );
}
