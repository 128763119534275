import styled from "styled-components";
import * as Theme from "../Theme";

const Button = styled.div`
  position: relative;
  z-index: 0;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 200px;
  padding: 13px 0px;
  border: 0.5px solid #717071;
  transition: all 0.2s;

  :hover {
    border: 0.5px solid ${Theme.colors.brown};
    color: #fff;

    & > svg {
      fill: #fff;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    z-index: -1;
    background-color: ${Theme.colors.brown};
    transition: width 0.3s ease-out;
  }

  &:hover::before {
    width: 100%;
  }
`;

export default Button;
